import React from 'react'
import { Helmet } from 'react-helmet'

import VignetteCertifs from './VignetteCertifs'
import VignetteList from '../VignetteList'

import './HomePage.css'

const HomePage = ({ contacts, sections, certifs }) => {
  return (
    <div className='homePage-container'>
      <Helmet>
        <title>MVO Formation - Site Officiel - Formations sur-mesure</title>
        <link rel='canonical' href={process.env.REACT_APP_CANONICAL_URL} />
        <meta name='description' content='MVO Formation - Traduit vos ambitions en résultats - Adaptée à vos objectifs !' />
      </Helmet>
      <div className='mvo-video'>
        <iframe src='https://www.youtube.com/embed/QcO26rAy2Xg' frameBorder='0' allowFullScreen title='Vidéo de présentation' />
      </div>
      <p className='homePage-prez'>{contacts.presentation}</p>
      <VignetteList sections={sections} />
      <VignetteCertifs certifs={certifs} />
    </div>
  )
}

export default HomePage
