import React from 'react'
import { Link } from 'react-router-dom'
import SocialNetworkLinks from './SocialNetworkLinks'

import LogoFooter from '../../Images/logofooter.png'

import './Footer.css'

const Footer = ({ contact, reseaux, sections: [section] }) => {
  return (
    <div className='footer-container'>
      <SocialNetworkLinks reseaux={reseaux} />
      <div className='footer-logo'>
        <img src={LogoFooter} alt='logo MVO formation' />
        {
          section && (
            <Link
              to={`/${section.url_section}/`}
              className='footer-link'
            >
              {section.titre}
            </Link>
          )
        }
      </div>
      <div className='footer-contacts'>
        <p className='footer-nom-societe'>{contact.nom_societe}</p>
        <p>{contact.adresse}</p>
        <p>{contact.cp} {contact.ville}</p>
        <p>{contact.num_tel}</p>
        <p>{contact.mail}</p>
      </div>

    </div>
  )
}

export default Footer
