import React from 'react'

import './ReferenceEntreprise.css'

const ReferenceEntreprise = ({ domaines, entreprises }) => {
  return (
    <div className='domaine-entreprise-container'>
      {domaines === null || entreprises === null ? (<div className='loader' />)
        : domaines.map(domaine =>
          (
            <div key={domaine.id}>
              <h2 className='domaines'>{domaine.nom}</h2><hr />
              <div className='logos-grid'>
                {entreprises.filter(entreprise => entreprise.nom_domaine === domaine.nom)
                  .map(entreprise =>
                    entreprise.logo ? <img key={entreprise.id} src={entreprise.logo} alt={entreprise.nom} className='logo-entreprise' /> : <p className='nom-entreprise'>{entreprise.nom}</p>)}
              </div>
            </div>
          )
        )}
    </div>
  )
}

export default ReferenceEntreprise
