import React, { useState, useEffect } from 'react'
import axios from 'axios'

import MemberTeam from './MemberTeam'

import './TeamList.css'

const TeamList = () => {
  const [equipe, setEquipe] = useState(null)

  const getEquipe = () => {
    axios.get('/api/equipes')
      .then(res => setEquipe(res.data))
  }

  useEffect(() => getEquipe(), [])

  return equipe === null ? (<div className='loader' />) : (
    <div className='teamList-container'>
      <h2 className='teamList-titre'>Equipe</h2>
      <hr />
      <div className='teamList-grid'>
        {equipe.map(membre => <MemberTeam key={membre.id} membre={membre} />)}
      </div>
    </div>
  )
}

export default TeamList
