import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import axios from 'axios'

import FormationTitle from './FormationTitle'
import Title from '../Title'
import SousCatTitle from './SousCatTitle'

import './FormationsListPage.css'

const FormationListPage = (props) => {
  const [listFormations, setListFormations] = useState(null)
  const [listSousCat, setListSousCat] = useState(null)
  const [listSousSousCat, setListSousSousCat] = useState(null)

  // const categorie = props.match.params.categorie
  const { categorie } = useParams()

  const getInfoFormation = () => {
    axios.get(`/api/formations/?categorie=${categorie}`)
      .then(res => {
        const nomsSousCat = res.data
          .filter(formation => formation.nom_scf !== null)
          .map(formation => formation.nom_scf)
        const tabSousCat = [...new Set(nomsSousCat)]
        setListSousCat(tabSousCat)
        const nomsSousSousCat = res.data
          .filter(formation => formation.nom_sscf !== null)
          .map(formation => formation.nom_sscf + ' / ' + formation.nom_scf)
        const tabSousSousCat = [...new Set(nomsSousSousCat)]
          .map(cat => cat.split(' / '))
        setListSousSousCat(tabSousSousCat)
        setListFormations(res.data)
      })
  }

  useEffect(() => getInfoFormation(), [])

  if (listFormations === null) return (<div className='loader' />)
  if (listFormations.length === 0) {
    return (
      <div className='formationListPage-container-empty'>
    Il n'y a pas de formation dans cette catégorie
      </div>)
  }
  return (
    <div className='formationListPage-container'>
      <Helmet>
        <title>{`Formations - ${listFormations[0].nom_cf} | MVO Formation`}</title>
        {
          listFormations.length > 0 && (
            <meta name='description' content={listFormations[0].meta_description} />
          )
        }
      </Helmet>
      <Title title={listFormations[0].nom_cf} />
      <div className='formationListPage-list'>
        {/* List formations sans sous cat */}
        {listFormations
          .filter(formation => formation.nom_scf === null)
          .map(formation => <FormationTitle formation={formation} key={formation.id} categorie={categorie} />)}
        {/* List cat */}
        {listSousCat.map((sousCat, index) =>
          (
            <div className='formationListPage-SC' key={index}>
              <SousCatTitle key={index} title={sousCat} />
              {/* List formation sous cat sans sous sous cat */}
              {listFormations
                .filter(formation => formation.nom_scf === sousCat && formation.nom_sscf === null)
                .map(formation =>
                  <FormationTitle formation={formation} key={formation.id} categorie={categorie} />)}
              <div className='formationListPage-SSC-container'>
                {/* List sous sous cat */}
                {listSousSousCat
                  .filter(sousSousCat => sousSousCat[1] === sousCat)
                  .map((sousSousCat, i) => (
                    <div className='formationListPage-SSC' key={i}>
                      <SousCatTitle key={i} title={sousSousCat[0]} SSCat />
                      {/* List formation sous cat et sous sous cat */}
                      {listFormations
                        .filter(formation => formation.nom_sscf === sousSousCat[0])
                        .map(formation => <FormationTitle formation={formation} key={formation.id} categorie={categorie} />)}
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default FormationListPage
