import React from 'react'
import { Link } from 'react-router-dom'

import BlueLine from '../../Images/minus.png'

import './FormationTitle.css'

const FormationTitle = ({ formation, categorie }) => {
  return (
    <div className='formationTitle-container'>
      <img src={BlueLine} alt='blue line' className='formationTitle-line' />
      <Link
        className='formationTitle-title'
        to={{ pathname: `/formations/${categorie}/${formation.url_fiche_formationcol}/`, aboutProps: { formation: formation } }}
      >
        {formation.titre}
      </Link>
    </div>
  )
}

export default FormationTitle
