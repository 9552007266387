import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import './NoMatch.css'

function NoMatch () {
  const location = useLocation()

  return (
    <div className='NoMatch'>
      <Helmet>
        <title>Erreur 404 | MVO Formation</title>
      </Helmet>
      <h1>Erreur 404</h1>
      <h3>
        Aucune page n&#39;existe à l&#39;adresse <code>{location.pathname}</code>.
      </h3>
      <Link className='NoMatch-back' to='/'>Retour à la page d&#39;accueil</Link>
    </div>
  )
}

export default NoMatch
