import React from 'react'

import './SubTitle.css'

const SubTitle = ({ subTitle }) => {
  return (
    <div className='subTitleDiv'>
      <p className='subTitleP'>{subTitle}</p>
    </div>
  )
}

export default SubTitle
