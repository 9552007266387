import React, { useState, useEffect } from 'react'
import axios from 'axios'

import './Sitemap.css'

const getAlt = t => `Transport : ${t.moyen_transport}`

const Sitemap = ({ sitemap }) => {
  const [transports, setTransports] = useState(null)

  const getTransports = () => {
    axios
      .get('/api/transports')
      .then((res) => setTransports(res.data))
  }
  useEffect(() => getTransports(), [])

  return transports === null ? (<div className='loader' />) : (
    <div className='sitemap-container'>
      <h2 className='sitemap-title'>Nous trouver</h2>
      <hr className='hr-color' />
      <div className='sitemap-flexbox'>
        <div className='sitemap-address'>
          <p className='sitemap-nom-societe'>{sitemap.nom_societe}</p>
          <p>{sitemap.adresse}</p>
          <p>{sitemap.cp} {sitemap.ville}</p>
          <p>{sitemap.num_tel}</p>
          <p>{sitemap.mail}</p>
          <br />
          <div className='sitemap-transport'>
            <div className='sitemap-station'>
              <img className='logoTransport' src={transports[0].logo_transport} alt={getAlt(transports[0])} />
              {transports[0].nom_station}
            </div>
            <div className='sitemap-station'>
              <img className='logoTransport' src={transports[1].logo_transport} alt={getAlt(transports[1])} />
              {transports[1].nom_station}
            </div>
          </div>
        </div>
        <div className='sitemap-map'>
          <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2627.0276270670997!2d2.2428382510897427!3d48.819534311294426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e67af66956bc05%3A0x6b1a3263ebad1e8f!2s64%20Rue%20Marcel%20Miquel%2C%2092130%20Issy-les-Moulineaux!5e0!3m2!1sfr!2sfr!4v1593511292943!5m2!1sfr!2sfr' title={`Plan d'accès au ${sitemap.adresse}, ${sitemap.cp} ${sitemap.ville}`} />
        </div>
      </div>
    </div>
  )
}

export default Sitemap
