import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'

import ReferenceEntreprise from './ReferenceEntreprise'
import SubTitle from '../SubTitle'
import Title from '../Title'

import './ReferencePage.css'

const ReferencePage = ({ section }) => {
  const [domaines, setDomaines] = useState(null)
  const [entreprises, setEntreprises] = useState(null)

  const getDomaines = () => {
    axios.get('/api/references/domaine')
      .then((res) => setDomaines(res.data))
  }

  const getEntreprises = () => {
    axios.get('/api/references/entreprise')
      .then((res) => setEntreprises(res.data))
  }

  useEffect(() => getDomaines(), [])
  useEffect(() => getEntreprises(), [])

  return (
    <div className='referencePage-container'>
      <Helmet>
        <title>{`${section.titre} | MVO Formation`}</title>
        <meta name='description' content={section.meta_description} />
      </Helmet>
      <Title title={section.titre} />
      {section.sous_titre ? <SubTitle subTitle={section.sous_titre} /> : ''}
      <ReferenceEntreprise domaines={domaines} entreprises={entreprises} />
    </div>
  )
}

export default ReferencePage
