import React, { useState } from 'react'
import axios from 'axios'
import './Formulaire.css'

const Formulaire = () => {
  const [data, setData] = useState({
    civilite: 'non renseigné',
    nom: 'non renseigné',
    prenom: 'non renseigné',
    societe: 'non renseigné',
    fonction: 'non renseigné',
    phone: 'non renseigné',
    email: 'non renseigné',
    message: 'non renseigné'
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    axios
      .post('/api/contacts-forms', data)
      .then((res) => res.data)
      .then((res) => alert(`${res}`))
      .catch((err) => alert(`erreur : ${err.response.data} `))
  }

  return (
    <form className='form' onSubmit={handleSubmit}>
      <h2>Nous écrire</h2>
      <hr className='hr-col' />
      <div className='form-container'>
        <div className='part'>
          <div className='testform'>
            <label htmlFor='Civilité'>Civilité</label>
            <select name='civilite' id='civilite' className='form-input' onChange={handleChange}>
              <option value='' />
              <option value='madame'>Madame</option>
              <option value='monsieur'>Monsieur</option>
            </select>
          </div>
          <div className='testform'>
            <label htmlFor='Nom'>Nom</label>
            <input type='text' name='nom' id='Nom' required className='form-input' onChange={handleChange} />
          </div>
          <div className='testform'>
            <label htmlFor='Prénom'>Prénom</label>
            <input type='text' name='prenom' id='Prénom' required className='form-input' onChange={handleChange} />
          </div>
          <div className='testform'>
            <label htmlFor='Société'>Société</label>
            <input type='text' name='societe' id='Société' className='form-input' onChange={handleChange} />
          </div>
          <div className='testform'>
            <label htmlFor='Fonction'>Fonction</label>
            <input type='text' name='fonction' id='Fonction' className='form-input' onChange={handleChange} />
          </div>
          <div className='testform'>
            <label htmlFor='phone'>Téléphone</label>
            <input type='tel' name='phone' id='phone' required className='form-input' onChange={handleChange} />
          </div>
          <div className='testform'>
            <label htmlFor='email'>E-mail</label>
            <input type='email' name='email' id='email' required className='form-input' onChange={handleChange} />
          </div>
        </div>
        <div className='textAndButton'>
          <label htmlFor='message' className='message-flex'>Message</label>
          <textarea name='message' id='message-contact' required className='form-textarea' onChange={handleChange} /><br />
          <input type='submit' value='ENVOYER' className='button-send' />
        </div>
      </div>
    </form>
  )
}
export default Formulaire
