import React from 'react'
import { NavLink } from 'react-router-dom'

import './Navbar.css'

const Navbar = ({ sections }) => {
  return (
    <div className='Navbar-container'>
      {sections.map((section) => (
        <NavLink
          to={`/${section.url_section}/`}
          className='TitreCourt'
          key={section.id}
        >
          {section.titre_court}
        </NavLink>
      ))}
    </div>
  )
}

export default Navbar
