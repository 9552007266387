import React from 'react'
import './SocialNetworkLinks.css'

const SocialNetworkLinks = ({ reseaux }) => {
  return (

    <div className='Social-icons'>
      <h4>Retrouvez nous sur :</h4>
      {reseaux.map((reseaux, index) => (
        <a href={reseaux.url} key={index} className={`${reseaux.logo} fa-2x`} />
      ))}
    </div>

  )
}

export default SocialNetworkLinks
