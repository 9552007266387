import React, { useState, useEffect } from 'react'
import axios from 'axios'

import Article from './Article'

const ArticleList = ({ urlSection }) => {
  const [article, setArticle] = useState(null)

  const getArticles = () => {
    axios
      .get(`/api/articles/?section=${urlSection}`)
      .then((res) => setArticle(res.data))
  }

  useEffect(() => getArticles(), [])

  return article === null
    ? (
      <div className='loader' />
    )
    : (
      <div className='articleList-container'>
        {article.map((article) => <Article key={article.id} title={article.title} contenu={article.contenu} />)}
      </div>
    )
}

export default ArticleList
