import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'

import Article from '../Article'
import Title from '../Title'

import './FicheFormation.css'

const FicheFormation = (props) => {
  const [formation, setFormation] = useState(null)

  const getFormation = () => {
    axios.get(`/api/formations/getFormation/${props.match.params.formation}`)
      .then(res => setFormation(res.data))
  }

  useEffect(() => getFormation(), [])

  return formation === null
    ? <div className='loader' />
    : (
      <>
        <Helmet>
          <title>{`Formation - ${formation.titre} | MVO Formation`}</title>
          {
            formation.meta_description && (
              <meta name='description' content={formation.meta_description} />
            )
          }
        </Helmet>
        <div className='ficheFormation-container'>
          <Title title={formation.titre} />
          {formation.objectif !== null ? <Article title='Objectif' contenu={formation.objectif} /> : ''}
          {formation.competences_visees !== null ? <Article title='A l’issue de la formation, les participants seront capables de :' contenu={formation.competences_visees} /> : ''}
          {formation.public_vise !== null ? <Article title='Public visé' contenu={formation.public_vise} /> : ''}
          {formation.nb_participants !== null ? <Article title='Nombre de participants' contenu={formation.nb_participants} /> : ''}
          {formation.duree !== null ? <Article title='Durée' contenu={formation.duree} /> : ''}
          {formation.pre_requis !== null ? <Article title='Pré-requis' contenu={formation.pre_requis} /> : ''}
          {formation.material !== null ? <Article title='Matériel' contenu={formation.material} /> : ''}
          {formation.methode !== null ? <Article title='Méthodes et pédagogie' contenu={formation.methode} /> : ''}
          {formation.thematique !== null ? <Article title='Thématique' contenu={formation.thematique} /> : ''}
        </div>
      </>
    )
}

export default FicheFormation
