import React from 'react'
import { Link } from 'react-router-dom'
import './VignetteList.css'

const VignetteList = ({ sections, categories }) => {
  if (sections !== undefined) {
    return (
      <h2 className='vignette-list-container'>
        {sections.map((section, index) => (
          <Link to={`/${section.url_section}/`} key={index} className={`vignette-${section.nom_couleur}`}>
            <p className='vignette-text'>{section.titre_court}</p>
          </Link>
        ))}
      </h2>
    )
  } else if (categories !== undefined) {
    return (
      <h2 className='vignette-list-container'>
        {categories.map((categorie) => (
          <Link to={`/formations/${categorie.url_categorie_formationcol}/`} key={categorie.id} className={`vignette-${categorie.nom_couleur}`}>
            <p className='vignette-text'>{categorie.nom}</p>
          </Link>
        ))}
      </h2>
    )
  } else {
    return (<div className='loader' />)
  }
}

export default VignetteList
