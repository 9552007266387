import React from 'react'
import { Link } from 'react-router-dom'

import './ContactHeader.css'

const ContactHeader = ({ contact }) => {
  return (
    <Link to='/contacts/'>
      <button className='button'>Contactez-nous<br />{contact.num_tel}</button>
    </Link>

  )
}

export default ContactHeader
