import React from 'react'
import { Helmet } from 'react-helmet'
import SubTitle from '../SubTitle'
import TeamList from './Team/TeamList'
import Title from '../Title'
import WorldMap from '../../Images/worldmap.png'

import './AboutPage.css'
import ArticleList from '../ArticleList'

const AboutPage = ({ section }) => {
  return (
    <div className='aboutPage-container'>
      <Helmet>
        <title>{`${section.titre} | MVO Formation`}</title>
        <meta name='description' content={section.meta_description} />
      </Helmet>
      <Title title={section.titre} />
      {section.sous_titre ? <SubTitle subTitle={section.sous_titre} /> : ''}
      <img className='WorldMap-img' src={WorldMap} alt='Carte du monde' />
      <ArticleList urlSection={section.url_section} />
      <TeamList />
    </div>
  )
}

export default AboutPage
