import React, { useState, useEffect } from 'react'

import './Title.css'

const Title = ({ title }) => {
  const [titleStart, settitleStart] = useState(null)
  const [titleEnd, settitleEnd] = useState(null)

  const handleTitle = (title) => {
    const indexOfLastSpace = title.lastIndexOf(' ')
    settitleStart(title.slice(0, indexOfLastSpace).toUpperCase())
    settitleEnd(title.slice(indexOfLastSpace).toUpperCase())
  }

  useEffect(() => handleTitle(title), [])

  return (
    <div>
      <h2 className='sectionTitle'>
        {titleStart}
        <span className='titleEnd'>{titleEnd}</span>
      </h2>
    </div>
  )
}

export default Title
