import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'

import SubTitle from '../SubTitle'
import Title from '../Title'
import VignetteList from '../VignetteList'

const FormationPage = ({ section }) => {
  const [categories, setCategories] = useState(null)

  const getCategories = () => {
    axios.get('/api/formations/categories')
      .then(res => setCategories(res.data))
  }

  useEffect(() => getCategories(), [])

  return (
    <div className='formationPage-container'>
      <Helmet>
        <title>{`${section.titre} | MVO Formation`}</title>
        {
          section.meta_description && (
            <meta name='description' content={section.meta_description} />
          )
        }
      </Helmet>
      <Title title={section.titre} />
      {section.sous_titre ? <SubTitle subTitle={section.sous_titre} /> : ''}      {
        categories === null ? <div className='loader' /> : <VignetteList categories={categories} />
      }
    </div>
  )
}

export default FormationPage
