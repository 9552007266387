import React from 'react'
import parse from 'html-react-parser'

import './Article.css'

const Article = ({ title, contenu }) => {
  return (
    <div className='article-container'>
      <div className='article'>
        <div className='article-titre'>
          <h2 className='text-titre'>{title}</h2>
        </div>
        <hr className='hr-color' />
        <div className='article-contenu'>
          {parse(contenu)}
        </div>
      </div>
    </div>
  )
}

export default Article
