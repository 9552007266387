import React from 'react'
import parse from 'html-react-parser'

import linkedin from '../../../Images/linkedin.png'
import mail from '../../../Images/mail.png'
import viadeo from '../../../Images/viadeo.png'

import './MemberTeam.css'

const MemberTeam = ({ membre }) => {
  return (
    <div className='memberTeam-container'>
      <h3 className='member-nom'>
        {membre.prenom} {membre.nom.toUpperCase()}
      </h3>
      <p className='member-fonction'>{membre.fonction}</p>
      <div className='member-logo'>
        <a href={`mailto:${membre.mail}`}>
          <img src={mail} alt='email logo' />
        </a>
        <a href={membre.linkedin}>
          <img src={linkedin} alt='linkedin logo' />
        </a>
        <a href={membre.viadeo}>
          <img src={viadeo} alt='viadeo logo' />
        </a>
      </div>
      {membre.expertise === null ? (
        ''
      ) : (
        <div className='member-info'>
          <h4>Zones d'expertise : </h4>
          <div>
            {parse(membre.expertise)}
          </div>
        </div>
      )}
      {membre.certification === null ? (
        ''
      ) : (
        <div className='member-info'>
          <h4>Certifications : </h4>
          <div>
            {parse(membre.certification)}
          </div>
        </div>
      )}
    </div>
  )
}

export default MemberTeam
