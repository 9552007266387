import React from 'react'

import './SousCatTitle.css'

const SousCatTitle = ({ SSCat, title }) => {
  return (
    <div className={SSCat ? 'sousSousCatTitle-container' : 'sousCatTitle-container'}>
      <p className={SSCat ? 'sousSousCatTitle-title' : 'sousCatTitle-title'}>{title}</p>
    </div>
  )
}

export default SousCatTitle
