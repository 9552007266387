import React, { useState, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import AboutPage from './Components/AboutPage/AboutPage'
import ChangeManagementPage from './Components/ChangeManagementPage/ChangeManagementPage'
import ContactPage from './Components/ContactPage/ContactPage'
import FicheFormation from './Components/FormationPage/FicheFormation'
import Footer from './Components/Navigation/Footer'
import FormationListPage from './Components/FormationPage/FormationsListPage'
import FormationPage from './Components/FormationPage/FormationPage'
import Header from './Components/Navigation/Header'
import HomePage from './Components/HomePage/HomePage'
import OutsourcedSalesPage from './Components/OutsourcedSalesPage/OutsourcedSalesPage'
import ReferencePage from './Components/ReferencePage/ReferencePage'
import ReformCertificationPage from './Components/ReformCertificationPage/ReformCertficationPage'
import ResultProcessPage from './Components/ResultProcessPage/ResultProcessPage'
import BuyingBehavior from './Components/BuyingBehavior/BuyingBehavior'
import ConditionsDutilisationPage from './Components/ConditionsDutilisationPage/ConditionsDutilisationPage'
import NoMatch from './Components/NoMatch'
import { initializeGA, onLocationChange } from './analytics'
import jsonLd from './json-ld.json'
import './App.css'

const AppMvo = () => {
  const [sections, setSections] = useState(null)
  const [footerSections, setFooterSections] = useState(null)
  const [contacts, setContacts] = useState(null)
  const [certifs, setCertifs] = useState(null)
  const [reseaux, setReseaux] = useState(null)
  const history = useHistory()

  const getSections = () => {
    axios
      .get('/api/sections')
      .then((res) => {
        const { data } = res
        const mainSections = data.filter((sect) => sect.location === 'navbar')
        setSections(mainSections)
        const footSections = data.filter((sect) => sect.location === 'footer')
        setFooterSections(footSections)
      })
  }

  const getContacts = () => {
    axios
      .get('/api/contacts')
      .then((res) => setContacts(res.data))
  }

  const getLiens = () => {
    axios.get('/api/liens-externes').then((res) => {
      const certifs = res.data.filter((lien) => lien.nom_type_lien === 'label')
      setCertifs(certifs)
      const reseaux = res.data.filter((lien) => lien.nom_type_lien === 'reseaux')
      setReseaux(reseaux)
    })
  }

  useEffect(() => getSections(), [])
  useEffect(() => getContacts(), [])
  useEffect(() => getLiens(), [])
  useEffect(() => {
    initializeGA()
    history.listen(onLocationChange)
  }, [])

  return contacts === null ||
    sections === null ||
    footerSections === null ||
    certifs === null ||
    reseaux === null ? (
      <div className='loader' />
    ) : (
      <div className='App'>
        <Helmet>
          <script type='application/ld+json'>{JSON.stringify(jsonLd)}</script>
        </Helmet>
        <Header sections={sections} contact={contacts[0]} />
        <Switch>
          <Route
            exact path='/'
            render={(props) => (
              <div>
                <HomePage
                  contacts={contacts[0]}
                  sections={sections}
                  certifs={certifs}
                />
              </div>
            )}
          />
          <Route
            path={`/${sections[0].url_section}`}
            render={(props) => <AboutPage section={sections[0]} />}
          />
          <Route
            path={`/${sections[1].url_section}/:categorie/:formation`}
            render={(props) => <FicheFormation {...props} />}
          />
          <Route
            path={`/${sections[1].url_section}/:categorie`}
            render={(props) => <FormationListPage {...props} />}
          />
          <Route
            path={`/${sections[1].url_section}`}
            render={(props) => <FormationPage section={sections[1]} />}
          />
          <Route
            path={`/${sections[2].url_section}`}
            render={(props) => <ChangeManagementPage section={sections[2]} />}
          />
          <Route
            path={`/${sections[3].url_section}`}
            render={(props) => <ReferencePage section={sections[3]} />}
          />
          <Route
            path={`/${sections[4].url_section}`}
            render={(props) => <ResultProcessPage section={sections[4]} />}
          />
          <Route
            path={`/${sections[5].url_section}`}
            render={(props) => <OutsourcedSalesPage section={sections[5]} />}
          />
          <Route
            path={`/${sections[6].url_section}`}
            render={(props) => <ContactPage section={sections[6]} contact={contacts[0]} />}
          />
          <Route
            path={`/${sections[7].url_section}`}
            render={(props) => <BuyingBehavior section={sections[7]} />}
          />
          <Route
            path={`/${sections[8].url_section}`}
            render={(props) => <ReformCertificationPage section={sections[8]} />}
          />
          <Route
            path={`/${footerSections[0].url_section}`}
            render={(props) => <ConditionsDutilisationPage section={footerSections[0]} />}
          />
          <Route
            path='*'
            component={NoMatch}
          />
        </Switch>
        <Footer contact={contacts[0]} reseaux={reseaux} sections={footerSections} />
      </div>
    )
}

export default AppMvo
