import React from 'react'
import { Helmet } from 'react-helmet'

import ArticleList from '../ArticleList'
import SubTitle from '../SubTitle'
import Title from '../Title'

import './ReformCertificationPage.css'

const ReformCertificationPage = ({ section }) => {
  return (
    <div className='reformCertificationPage-container'>
      <Helmet>
        <title>{`${section.titre} | MVO Formation`}</title>
        <meta name='description' content={section.meta_description} />
      </Helmet>
      <Title title={section.titre} />
      {section.sous_titre ? <SubTitle subTitle={section.sous_titre} /> : ''}
      <ArticleList urlSection={section.url_section} />
    </div>
  )
}

export default ReformCertificationPage
