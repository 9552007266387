import React, { useState } from 'react'

import burgerMenu from '../../Images/burger-menu.png'
import burgerClose from '../../Images/burger-close.png'

import './BurgerMenu.css'
import { Link } from 'react-router-dom'

const BurgerMenu = ({ sections }) => {
  const [isMenu, setIsMenu] = useState(false)

  const displayMenuSide = () => {
    setIsMenu(true)
  }

  const hideMenuSide = (e) => {
    setIsMenu(false)
  }

  return sections === null ? (
    'Loading'
  ) : (
    <div className='burgerMenu-container'>
      <img
        src={burgerMenu}
        alt='burger menu'
        className='burgerMenu-menu'
        onClick={displayMenuSide}
      />
      <div className={`burgerMenu-side ${isMenu ? '' : 'burgerMenu-hide'}`}>
        <img
          src={burgerClose}
          alt='close menu'
          className='burgerMenu-close'
          onClick={hideMenuSide}
        />
        <div className='burgerMenu-links'>
          {sections.map((section) => (
            <Link
              to={`/${section.url_section}/`}
              className='burgerMenu-Link'
              key={section.id}
              onClick={hideMenuSide}
            >
              {section.titre_court}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BurgerMenu
