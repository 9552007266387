import React from 'react'
import { Link } from 'react-router-dom'

import BurgerMenu from './BurgerMenu'
import NavBar from './Navbar'
import ContactHeader from './ContactHeader'

import LogoHeader from '../../Images/logoheader.png'

import './Header.css'

const Header = ({ sections, contact }) => {
  return (
    <div className='header-container'>
      <div className='header-block'>
        <BurgerMenu sections={sections} />
        <Link to='/'>
          <img
            src={LogoHeader}
            alt='logo MVO formation'
            className='header-logo'
          />
        </Link>
        <NavBar sections={sections} />
      </div>
      <div className='header-contact'>
        <ContactHeader contact={contact} />
      </div>
    </div>
  )
}

export default Header
