import ReactGA from 'react-ga'

// Initialize Google Analytics
export const initializeGA = () => {
  const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
  const cookieDomain = process.env.REACT_APP_GOOGLE_ANALYTICS_DOMAIN
  ReactGA.initialize(trackingId, {
    // debug: true,
    gaOptions: {
      cookieName: 'gaCookie',
      cookieDomain,
      cookieExpires: 60 * 60 * 24 * 28,
      cookieUpdate: 'false',
      cookieFlags: 'SameSite=None; Secure'
    }
  })
}

// Listen to location changes
export const onLocationChange = location => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
}
