import React from 'react'
import { Helmet } from 'react-helmet'
import SubTitle from '../SubTitle'
import Title from '../Title'
import Formulaire from './Formulaire'
import Sitemap from './Sitemap'

import './ContactPage.css'

const ContactPage = ({ section, contact }) => {
  return (
    <div className='contactPage-container'>
      <Helmet>
        <title>{`${section.titre} | MVO Formation`}</title>
        <meta name='description' content={section.meta_description} />
      </Helmet>
      <Title title={section.titre} />
      {section.sous_titre ? <SubTitle subTitle={section.sous_titre} /> : ''}
      <Formulaire />
      <Sitemap sitemap={contact} />
    </div>
  )
}

export default ContactPage
