import React from 'react'
import { Helmet } from 'react-helmet'

import ArticleList from '../ArticleList'
import SubTitle from '../SubTitle'
import Title from '../Title'
import GraphiqueComportement from '../../Images/graphique_comportement.gif'
import './BuyingBehavior.css'

const BuyingBehavior = ({ section }) => {
  return (
    <div className='BuyingBehavior-container'>
      <Helmet>
        <title>{`${section.titre} | MVO Formation`}</title>
        <meta name='description' content={section.meta_description} />
      </Helmet>
      <Title title={section.titre} />
      {section.sous_titre ? <SubTitle subTitle={section.sous_titre} /> : ''}
      <img className='GraphiqueComportement-img' src={GraphiqueComportement} alt='graphique comportement image' />
      <ArticleList urlSection={section.url_section} />
    </div>
  )
}

export default BuyingBehavior
