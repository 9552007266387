import React from 'react'

import './VignetteCertifs.css'

const VignetteCertifs = ({ certifs }) => {
  return (
    <h3 className='logo-certif-container'>
      {certifs.map((certif, index) => (
        <a key={index} href={certif.url}>
          <img
            src={certif.logo}
            alt={`Logo ${certif.nom}`}
            className='logo-certif'
          />
        </a>
      ))}
    </h3>
  )
}

export default VignetteCertifs
